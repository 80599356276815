<template>
  <div class="bg-white container">
    <DxGridWithSearch
      :title="title"
      keyExpr="id"
      :dataSource="dataSource"
      :toolbar="toolbar"
      :columns="columns"
    >
      <template v-slot:extraActions>
        <div class="d-flex justify-content-end">test</div>
      </template>
    </DxGridWithSearch>
    <modal :status="isModalOpen" @close="isModalOpen = false">
      <form @submit.prevent="handleSubmit" class="form container">
        <div class="row">
          <h2 class="col-12">
            {{ title }}
          </h2>
        </div>

        <!-- Cytology Diagnostic Codes Fields -->
        <div v-if="type == cytologyEntities.CytDiagnosticCodes" class="row">
          <TextInput class="col-12" label="Name" v-model="form.name" name="name" />

          <SelectInput
            class="col-6 col-md-3"
            label="Type"
            name="cytTypeId"
            v-model="form.cytTypeId"
            :items="cytologyTypes"
          ></SelectInput>
          <TextInput class="col-6 col-md-3" label="Code" v-model="form.code" name="code" />
          <SelectInput
            class="col-6 col-md-3"
            label="Code Group"
            v-model="form.cytCodeGroupId"
            name="cytCodeGroupId"
            :items="cytCodeGroups"
          />
          <SelectInput
            class="col-6 col-md-3"
            label="Review Type"
            v-model="form.cytReviewTypeId"
            name="cytReviewTypeId"
            :items="cytReviewTypes"
          />
          <TagInput
            class="col-6"
            label="Modifiers"
            v-model="form.mandatoryModifiers"
            name="mandatoryModifiers"
            :dataSource="cytologyCodesSearch"
          />
          <TagInput
            class="col-12 col-md-6"
            name="Icd Codes"
            label="Icd Codes"
            v-model="form.icdCodes"
            :dataSource="icdCodesStore"
          />
          <NumberInput
            class="col-6 col-md-3"
            label="Severity"
            v-model="form.severityLevel"
            name="severityLevel"
          />
          <SelectInput
            class="col-6 col-md-3"
            label="Discrepancy Notification"
            name="discrepancyNotification"
            v-model="form.discrepancyNotification"
            :items="yesNo"
          >
          </SelectInput>
          <GeneralMacroEnabledEditor
            required
            class="col-12"
            label="Formatted Text"
            v-model="form.formattedText"
            name="formattedText"
          />
        </div>

        <!-- Cytology Questions Fields -->
        <div v-if="type == cytologyEntities.CytQuestions" class="row">
          <TextInput class="col-6" label="Sequence Number" v-model="form.seqNum" name="seqNum" />
          <SelectInput
            class="col-6"
            label="Question Type"
            name="cytQuestionTypeId"
            v-model="form.cytQuestionTypeId"
            :items="cytQuestionTypes"
          />
          <TextInput
            class="col-12"
            label="Display Text"
            v-model="form.displayText"
            name="displayText"
          />
          <SelectInput
            class="col-3"
            label="Is Response Required"
            name="isResponseRequired"
            v-model="form.isResponseRequired"
            :items="yesNo"
          />
          <fieldset
            v-if="form.cytQuestionTypeId === listQuestionType"
            class="col-9"
            label="Options"
          >
            <legend>Options</legend>
            <dx-data-grid :toolbar="subToolbar" :columns="subCols" :dataSource="form.options" />
            <modal :status="isSubModalOpen" @close="isSubModalOpen = false">
              <form class="container" @submit.prevent="handleSubmitSubForm">
                <div class="row">
                  <h2 class="col-12">Add Option</h2>
                  <TextInput
                    required
                    class="col-8"
                    label="Option Text"
                    v-model="subform.displayName"
                    name="displayName"
                  />
                  <button class="col offset-9 btn btn-primary" type="submit">Save</button>
                </div>
              </form>
            </modal>
          </fieldset>
        </div>

        <!-- Cytology Diagnostic Macros Fields -->
        <div v-if="type == cytologyEntities.CytDiagnosticMacros" class="row">
          <TextInput
            class="col-6"
            label="Display Name"
            v-model="form.displayName"
            name="displayName"
          />
          <SelectInput
            class="col-6"
            label="Type"
            name="cytTypeId"
            v-model="form.cytTypeId"
            :items="cytologyTypes"
          ></SelectInput>
          <TextAreaInput
            class="col-12"
            label="Description"
            v-model="form.description"
            name="description"
          />
          <fieldset class="col-9" label="Questions">
            <legend>Codes</legend>
            <dx-data-grid
              :row-dragging="rowDrag"
              keyExpr="seqNum"
              :toolbar="subToolbar"
              :columns="subCols"
              :dataSource="form.codes"
            />
            <modal :status="isSubModalOpen" @close="isSubModalOpen = false">
              <form class="container" @submit.prevent="handleSubmitSubForm">
                <div class="row">
                  <h2 class="col-12">Add Code</h2>
                  <SelectInput
                    required
                    class="col-6"
                    label="Diagnosis Code"
                    v-model="subform.cytDiagnosticCodeId"
                    name="cytDiagnosticCodeId"
                    :dataSource="cytologyCodesSearch"
                  />
                  <NumberInput
                    required
                    class="col-6"
                    label="Sequence Number"
                    v-model="subform.seqNum"
                    name="seqNum"
                  />
                  <button class="col offset-9 btn btn-primary" type="submit">Save</button>
                </div>
              </form>
            </modal>
          </fieldset>
        </div>
        <!-- Cytology Molecular Results Fields && Cytology Molecular Panels Fields -->
        <div
          v-if="
            type == cytologyEntities.CytMolecResults ||
            type == cytologyEntities.CytMolecPanels ||
            type == cytologyEntities.CytMolecTests
          "
          class="row"
        >
          <TextInput
            class="col-8"
            label="Display Text"
            v-model="form.displayText"
            name="displayText"
          />
        </div>

        <!-- Cytology Panels Fields -->
        <div class="row" v-if="type == cytologyEntities.CytMolecPanels">
          <tag-input
            class="col-4"
            label="Billing Tx Codes"
            v-model="form.billingTransactionCodes"
            name="results"
            displayExpr="code"
            :dataSource="billingCodesStore"
          />

          <TagInput
            class="col-12 col-md-6"
            name="Icd Codes"
            label="Icd Codes"
            v-model="form.icdCodes"
            :dataSource="icdCodesStore"
          />
          <fieldset class="col-6" label="Molecular Tests">
            <legend>Tests</legend>
            <dx-data-grid
              :row-dragging="rowDrag"
              keyExpr="seqNum"
              :dataSource="form.tests"
              :toolbar="subToolbar"
              :columns="subCols"
            />
            <modal :status="isSubModalOpen" @close="isSubModalOpen = false">
              <form class="container" @submit.prevent="handleSubmitSubForm">
                <div class="form-row">
                  <h2 class="col-12">Add Test</h2>
                  <SelectInput
                    class="col-6"
                    label="Molecular Test"
                    name="cytMolecTestId"
                    v-model="subform.cytMolecTestId"
                    :dataSource="molecTestsSearch"
                  />
                  <NumberInput
                    class="col-6"
                    label="Seq Num"
                    v-model="subform.seqNum"
                    name="seqNum"
                  />
                  <button class="col offset-11 btn btn-primary" type="submit">Save</button>
                </div>
              </form>
            </modal>
          </fieldset>
        </div>
        <!-- Cytology Triggers Fields -->
        <div class="row" v-if="type == cytologyEntities.CytTriggers">
          <SelectInput
            class="col-6"
            label="Provider"
            name="providerId"
            v-model="form.providerId"
            :displayExpr="providerExpr"
            :dataSource="providersSearch"
          />
          <SelectInput
            class="col-6"
            label="Diagnostic Code"
            name="cytDiagnosticCodeId"
            v-model="form.cytDiagnosticCodeId"
            :dataSource="cytologyCodesSearch"
          />
          <SelectInput
            class="col-6"
            label="Molecular Result"
            name="cytMolecResultId"
            v-model="form.cytMolecResultId"
            :dataSource="molecResultsSearch"
          />
          <SelectInput
            class="col-6"
            label="Molecular Test"
            name="cytMolecTestId"
            v-model="form.cytMolecTestId"
            :dataSource="molecTestsSearch"
          />
          <TagInput
            class="col-6"
            label="Tests"
            name="tests"
            v-model="form.tests"
            :dataSource="molecTestsSearch"
          />
          <SelectInput
            class="col-6"
            label="Allow Cytology Prelims"
            name="allowCytologyPrelims"
            v-model="form.allowCytologyPrelims"
            :items="yesNo"
          />
          <SelectInput
            class="col-6"
            label="Allow Multiple Prelims"
            name="allowMultiplePrelims"
            v-model="form.allowMultiplePrelims"
            :items="yesNo"
          />
          <SelectInput
            class="col-6"
            label="Allow Prelims After Pap Diagnosis"
            name="allowPrelimsAfterPapDiagnosis"
            v-model="form.allowPrelimsAfterPapDiagnosis"
            :items="yesNo"
          />
          <NumberInput class="col-6" label="Max Age" v-model="form.maxAge" name="maxAge" />
          <NumberInput
            class="col-6"
            label="Max Severity"
            v-model="form.maxSeverity"
            name="maxSeverity"
          />
          <NumberInput
            class="col-6"
            label="Min Severity"
            v-model="form.minSeverity"
            name="minSeverity"
          />
          <NumberInput
            class="col-6"
            label="Wait Months"
            v-model="form.waitMonths"
            name="waitMonths"
          />
        </div>
        <!-- Cytology Molecular Tests Fields -->
        <div class="row wrap" v-if="type === cytologyEntities.CytMolecTests">
          <number-input class="col-4" label="Seq Num" v-model="form.seqNum" name="seqNum" />
          <text-input class="col-4" label="Method" v-model="form.method" name="method" />
          <text-input
            class="col-4"
            label="Composition"
            v-model="form.composition"
            name="composition"
          />
          <select-input
            class="col-4"
            label="Normal Result"
            v-model="form.normalResultId"
            name="normalResultId"
            :dataSource="cytologyResults"
          />
          <tag-input
            class="col-4"
            label="Billing Tx Codes"
            v-model="form.billingTransactionCodes"
            name="results"
            displayExpr="code"
            :dataSource="billingCodesStore"
          />
          <fieldset class="col-9" label="Responses">
            <legend>Responses</legend>
            <dx-data-grid :toolbar="subToolbar" :columns="subCols" :dataSource="form.results" />
            <modal :status="isSubModalOpen" @close="isSubModalOpen = false">
              <form class="container" @submit.prevent="handleSubmitSubForm">
                <div class="row wrap">
                  <h2 class="col-12">Add Response</h2>
                  <SelectInput
                    class="col-6 col-md-4"
                    label="Molec Result"
                    name="cytMolecResultId"
                    v-model="subform.cytMolecResultId"
                    :dataSource="molecResultsSearch"
                  />
                  <SelectInput
                    class="col-6 col-md-4"
                    :dataSource="icdCodesStore"
                    v-model="subform.icdCodeId"
                    label="Icd Code"
                    name="icdCodes"
                  />
                </div>
                <div class="row"></div>
                <div class="row">
                  <GeneralMacroEnabledEditor
                    required
                    class="col-12"
                    label="Explanation Text"
                    v-model="subform.explanation"
                    name="explanation"
                  />
                </div>
                <div class="row">
                  <button class="col offset-9 btn btn-primary" type="submit">Save</button>
                </div>
              </form>
            </modal>
          </fieldset>
        </div>
        <div class="row" v-if="hasEffectiveDates">
          <DatePicker
            class="col-6"
            label="Effective Date"
            v-model="form.effectiveOn"
            name="effectiveDate"
          />
          <DatePicker class="col-6" label="Expiry Date" v-model="form.expiryOn" name="expiryOn" />
        </div>
        <div class="row justify-content-end mt-4">
          <button class="btn btn-primary" type="submit">Save</button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import DxGridWithSearch from "@/components/common/DxGridWithSearch.vue";
import cytologyService, { CytologyEntities } from "@/services/cytology.js";
import modal from "@/components/common/Modal.vue";
import {
  CytTypeEnum,
  enumToDropDown,
  CytQuestionTypeEnum,
  CytCodeGroupEnum,
  CytReviewTypeEnum
} from "@/modules/enums";
import DataSource from "devextreme/data/data_source";
import ProviderService from "@/services/providers";
import billingTxCode from "@/services/Billing";
import DropdownApi from "@/services/dropdown";
export default {
  components: {
    DxGridWithSearch,
    modal,
    TextInput: () => import("@/components/common/TextInput.vue"),
    SelectInput: () => import("@/components/common/SelectInput.vue"),
    DatePicker: () => import("@/components/common/DatePicker.vue"),
    GeneralMacroEnabledEditor: () => import("@/components/common/GeneralMacroEnabledEditor.vue"),
    TextAreaInput: () => import("@/components/TextAreaInput.vue"),
    NumberInput: () => import("@/components/common/NumberInput.vue"),
    TagInput: () => import("@/components/common/TagInput.vue"),
    DxDataGrid: () => import("devextreme-vue/data-grid")
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  name: "CytlogyCodesBrowse",
  mounted() {
    this.$on("reload", () => {
      this.dataSource.reload();
    });
  },
  beforeDestroy() {
    this.$off("reload");
  },
  computed: {
    tests() {
      if (this.form.tests) {
        return [...this.form?.tests].sort((a, b) => a.seqNum - b.seqNum);
      }
      return [];
    },
    defaultForm() {
      switch (this.type) {
        case CytologyEntities.CytDiagnosticCodes:
          return {
            icdCodes: [],
            mandatoryModifiers: []
          };
        case CytologyEntities.CytQuestions:
          return {
            options: []
          };
        case CytologyEntities.CytMolecPanels:
          return {
            tests: []
          };
        case CytologyEntities.CytMolecTests:
          return {
            results: []
          };
        default:
          return {};
      }
    },
    toolbar() {
      return {
        items: [
          {
            name: "test",
            widget: "dxButton",
            visible: true,
            options: {
              icon: "add",
              onClick: () => {
                this.isModalOpen = true;
                this.form = {
                  effectiveOn: new Date(),
                  ...this.defaultForm
                };
              }
            }
          }
        ]
      };
    },
    title() {
      switch (this.type) {
        case CytologyEntities.CytDiagnosticCodes:
          return "Cytology Diagnostic Codes ";
        case CytologyEntities.CytDiagnosticMacros:
          return "Cytology Diagnostic Macros";
        case CytologyEntities.CytQuestions:
          return "Cytology Questions";
        case CytologyEntities.CytMolecResults:
          return "Cytology Molecular Results";
        case CytologyEntities.CytMolecPanels:
          return "Cytology Molecular Panels";
        case CytologyEntities.CytTriggers:
          return "Cytology Triggers";
        case CytologyEntities.CytMolecTests:
          return "Cytology Molecular Tests";
        default:
          return "";
      }
    },
    dataSource() {
      const options = {
        store: cytologyService.createSearch(this.type)
      };

      return new DataSource(options);
    },
    subCols() {
      let cols = [];
      const command = {
        type: "buttons",
        buttons: [
          {
            hint: "Edit",
            icon: "edit",
            onClick: this.editSubRow
          },
          {
            hint: "Delete",
            icon: "trash",
            onClick: this.deleteSubRow
          }
        ]
      };

      switch (this.type) {
        case CytologyEntities.CytMolecTests:
          cols = [
            {
              dataField: "explanation",
              caption: "Explanation",
              encodeHtml: false
            },
            {
              dataField: "cytMolecResultId",
              caption: "Molecular Test",
              lookup: {
                dataSource: this.molecResultsSearch.store(),
                valueExpr: "id",
                displayExpr: "displayText"
              }
            }
          ];
          break;
        case CytologyEntities.CytDiagnosticMacros:
          cols = [
            {
              dataField: "seqNum",
              caption: "Sequence No.",
              sortIndex: 0,
              sortOrder: "asc"
            },
            {
              dataField: "cytDiagnosticCodeId",
              caption: "Diagnostic Code",
              lookup: {
                dataSource: this.cytologyCodesSearch,
                valueExpr: "id",
                displayExpr: "name"
              }
            }
          ];
          break;
        case CytologyEntities.CytMolecPanels:
          cols = [
            {
              dataField: "seqNum",
              caption: "Sequence No.",
              sortIndex: 0,
              sortOrder: "asc"
            },
            {
              dataField: "cytMolecTestId",
              caption: "Molecular Test",
              lookup: {
                dataSource: this.molecTestsSearch,
                valueExpr: "id",
                displayExpr: "displayText"
              }
            }
          ];
          break;
        case CytologyEntities.CytQuestions:
          cols = [
            {
              dataField: "displayName",
              caption: "Option Text"
            }
          ];
          break;

        default:
          cols = [];
          break;
      }
      return [...cols, command];
    },
    columns() {
      const expirationFields = [
        {
          dataField: "effectiveOn",
          caption: "Effective Date",
          dataType: "date"
        },
        {
          dataField: "expiryOn",
          caption: "Expiry Date",
          dataType: "date"
        }
      ];
      const actions = [
        {
          type: "buttons",
          buttons: [
            {
              hint: "Edit",
              icon: "edit",
              onClick: this.editRow
            },
            {
              hint: "Delete",
              icon: "trash",
              onClick: this.deleteRow
            }
          ]
        }
      ];
      let cols = [];
      switch (this.type) {
        case CytologyEntities.CytDiagnosticCodes:
          cols = [
            {
              dataField: "name",
              caption: "Name"
            },
            { dataField: "cytType", caption: "Cytology Type" },
            {
              dataField: "cytCodeGroup",
              caption: "Code Group"
            },
            {
              dataField: "code",
              caption: "Code"
            },
            {
              dataField: "severityLevel",
              caption: "Severity Level"
            },
            {
              dataField: "discrepancyNotification",
              caption: "Discrepancy Notification",
              lookup: {
                dataSource: this.yesNo,
                valueExpr: "id",
                displayExpr: "name"
              }
            },
            ...expirationFields
          ];
          break;
        case CytologyEntities.CytDiagnosticMacros:
          cols = [
            {
              dataField: "displayName",
              caption: "Name"
            },
            {
              dataField: "description",
              caption: "Name"
            },
            {
              dataField: "cytType",
              caption: "Cytology Type"
            },
            ...expirationFields
          ];
          break;
        case CytologyEntities.CytQuestions:
          cols = [
            {
              dataField: "seqNum",
              caption: "Sequence Number"
            },
            {
              dataField: "cytQuestionType",
              caption: "Question Type"
            },
            {
              dataField: "displayText",
              caption: "Display Text"
            },
            {
              dataField: "isResponseRequired",
              caption: "Required"
            },
            ...expirationFields
          ];
          break;
        case CytologyEntities.CytTriggers:
          cols = [
            {
              dataField: "providerId",
              lookup: {
                dataSource: ProviderService.searchProviders,
                valueExpr: "id",
                displayExpr: this.providerExpr
              }
            },
            {
              dataField: "cytDiagnosticCodeId",
              lookup: {
                dataSource: cytologyService.createSearch(CytologyEntities.CytDiagnosticCodes),
                valueExpr: "id",
                displayExpr: "name"
              }
            },
            {
              dataField: "cytMolecResultId",
              lookup: {
                dataSource: cytologyService.createSearch(CytologyEntities.CytMolecResults),
                valueExpr: "id",
                displayExpr: "displayText"
              }
            },
            {
              dataField: "cytMolecTestId",
              lookup: {
                dataSource: cytologyService.createSearch(CytologyEntities.CytMolecTests),
                valueExpr: "id",
                displayExpr: "displayText"
              }
            },
            {
              dataField: "allowCytologyPrelims"
            },
            {
              dataField: "allowMultiplePrelims"
            },
            {
              dataField: "allowPrelimsAfterPapDiagnosis"
            },

            {
              dataField: "maxAge"
            },
            {
              dataField: "maxSeverity"
            },
            {
              dataField: "minSeverity"
            },

            {
              dataField: "waitMonths"
            }
          ];
          break;
        case CytologyEntities.CytMolecTests:
          cols = [
            { dataField: "displayText", caption: "Name" },
            {
              dataField: "seqNum"
            },
            {
              dataField: "method"
            },
            { dataField: "composition" },
            {
              dataField: "normalResultId",
              caption: "Normal Result",
              lookup: {
                dataSource: this.cytologyResults,
                valueExpr: "id",
                displayExpr: "displayText"
              }
            },
            ...expirationFields
          ];
          break;
        case CytologyEntities.CytMolecPanels:
          cols = [{ dataField: "displayText", caption: "Name" }, ...expirationFields];
          break;
        default:
          cols = [{ dataField: "displayText", caption: "Name" }];
          break;
      }
      return [...cols, ...actions];
    },
    hasEffectiveDates() {
      return this.columns.some(c => c.dataField === "effectiveOn");
    }
  },
  data() {
    return {
      rowDrag: {
        allowReordering: true,
        dropFeedbackMode: "push",
        onReorder: this.handleMove
      },
      subToolbar: {
        items: [
          {
            name: "add",
            widget: "dxButton",
            options: {
              icon: "add",
              onClick: () => {
                this.isSubModalOpen = true;
                this.subform = {};
              }
            }
          }
        ]
      },
      subform: {},
      isSubModalOpen: false,
      cytCodeGroups: enumToDropDown(CytCodeGroupEnum),
      icdCodesStore: new DataSource({
        store: DropdownApi.searchIcdCodes
      }),
      cytologyCodesSearch: cytologyService.createSearch(CytologyEntities.CytDiagnosticCodes),
      molecResultsSearch: new DataSource({
        store: cytologyService.createSearch(CytologyEntities.CytMolecResults)
      }),

      molecTestsSearch: cytologyService.createSearch(CytologyEntities.CytMolecTests),
      billingCodesStore: billingTxCode.searchStore,
      yesNo: [
        { id: true, name: "Yes" },
        { id: false, name: "No" }
      ],
      cytReviewTypes: enumToDropDown(CytReviewTypeEnum),
      providersSearch: new DataSource({
        store: ProviderService.searchProviders
      }),
      cytologyEntities: CytologyEntities,
      cytQuestionTypes: enumToDropDown(CytQuestionTypeEnum),
      listQuestionType: CytQuestionTypeEnum.List,
      cytologyTypes: enumToDropDown(CytTypeEnum),
      cytologyResults: cytologyService.createSearch(CytologyEntities.CytMolecResults),
      isEditing: false,
      isModalOpen: false,
      form: {}
    };
  },
  watch: {
    isSubModalOpen(val) {
      if (!val) {
        this.subform = {};
        this.isEditing = false;
      }
    },
    isModalOpen(val) {
      if (!val) {
        this.form = {};
        this.isEditing = false;
      }
    }
  },
  methods: {
    editSubRow(e) {
      const { row } = e;
      this.subform = row.data;
      this.isEditing = true;
      this.isSubModalOpen = true;
    },

    handleSubmitSubForm() {
      if (!this.isEditing) {
        switch (this.type) {
          case CytologyEntities.CytQuestions:
            this.form.options = [...(this.form.options || []), this.subform];
            break;
          case CytologyEntities.CytMolecPanels:
            {
              this.form.tests = [...(this.form.tests || []), this.subform];
            }
            break;
          case CytologyEntities.CytMolecTests:
            {
              this.form.results = [...(this.form.results || []), this.subform];
            }
            break;
          default:
            break;
        }
      }
      this.subform = {};
      this.isSubModalOpen = false;
    },
    deleteSubRow(e) {
      const { row } = e;
      const { data } = row;
      switch (this.type) {
        case CytologyEntities.CytQuestions:
          this.form.options = this.form.options.filter(e => e !== data);
          break;
        case CytologyEntities.CytMolecPanels:
          this.form.tests = this.form.tests.filter(e => e !== data);
          break;
        case CytologyEntities.CytMolecTests:
          this.form.results = this.form.results.filter(e => e !== data);
          break;
        default:
          break;
      }
    },
    providerExpr(data) {
      if (!data) return "";
      return `${data.firstName} ${data.lastName} (${data.npi})`;
    },
    handleMove(event) {
      const { toIndex, fromIndex } = event;
      if (toIndex === -1) return;
      if (toIndex === fromIndex) return;

      let items = event.component.getVisibleRows();
      if (items.length) {
        items = items.map(e => e.data);
      }

      items.splice(fromIndex, 1);
      items.splice(toIndex, 0, event.itemData);
      switch (this.type) {
        case CytologyEntities.CytMolecPanels:
          this.form.tests = items.map((e, i) => ({
            ...e,
            seqNum: i + 1
          }));
          break;
        case CytologyEntities.CytDiagnosticMacros:
          this.form.codes = items.map((e, i) => ({
            ...e,
            seqNum: i + 1
          }));
          break;
        default:
          break;
      }
    },
    async editRow(e) {
      const { row } = e;
      const {
        data: { id }
      } = row;
      const details = await cytologyService.getSingleEntity(this.type, id);
      this.form = details;
      this.isModalOpen = true;
    },
    async deleteRow(e) {
      const confirm = await window.confirm("Are you sure you want to delete this record?");
      if (!confirm) return;
      const { row } = e;
      const {
        data: { id }
      } = row;
      await cytologyService.deleteEntity(this.type, id);
      this.dataSource.reload();
    },
    async handleSubmit() {
      try {
        if (this.form.id) {
          await cytologyService.updateEntity(this.type, this.form);
        } else {
          await cytologyService.createEntity(this.type, this.form);
        }
        this.isModalOpen = false;
        this.dataSource.reload();
      } catch (error) {
        console.log(error);
        window.notify("An error occurred while saving the record", "error");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
